// 3rd
import { z } from 'zod';

// App - Types
import type { KeyOf } from '../../keyof';
import type {
  KnowledgeItemLabels,
  KnowledgeItemLabelData,
  KnowledgeItemAccessControlLabel,
  KnowledgeItemAiRelatedFeatureLabel,
  KnowledgeItemApiLabel,
  KnowledgeItemCryptographyLabel,
  KnowledgeItemDataChangesLabel,
  KnowledgeItemInfrastructureLabel,
  KnowledgeItemThirdPartyComponentLabel,
  KnowledgeItemUserFlowsLabel,
} from '../knowledge-item-labels';

export const KnowledgeItemLabelsConfigurationDto = [
  'AccessControl',
  'AiRelatedFeature',
  'Api',
  'Architecture',
  'BusinessLogic',
  'Cryptography',
  'DataChanges',
  'DesignDecisions',
  'Infrastructure',
  'UserFlows',
  'Pii',
  'SensitiveInformation',
  'ThirdPartyComponent',
  'UNKNOWN',
] as const;

export type KnowledgeItemLabelConfigurationDto =
  (typeof KnowledgeItemLabelsConfigurationDto)[number];

export const castKnowledgeItemLabelConfigurationDtoToKeyOfKnowledgeItemLabels = (
  dto: KnowledgeItemLabelConfigurationDto
): KeyOf<KnowledgeItemLabels> => {
  if (dto === 'AccessControl') return 'accessControl';

  if (dto === 'AiRelatedFeature') return 'aiRelatedFeature';

  if (dto === 'Api') return 'api';

  if (dto === 'Architecture') return 'architecture';

  if (dto === 'BusinessLogic') return 'businessLogic';

  if (dto === 'Cryptography') return 'cryptography';

  if (dto === 'DataChanges') return 'dataChanges';

  // if (dto === 'DesignDecisions') return 'designDecisions';

  if (dto === 'Infrastructure') return 'infrastructure';

  if (dto === 'UserFlows') return 'userFlows';

  if (dto === 'Pii') return 'pii';

  if (dto === 'SensitiveInformation') return 'sensitiveInformation';

  if (dto === 'ThirdPartyComponent') return 'thirdPartyComponent';

  return 'api';
};

export const castKeyOfKnowledgeItemLabelsToKnowledgeItemLabelConfigurationDto = (
  key: KeyOf<KnowledgeItemLabels>
): KnowledgeItemLabelConfigurationDto => {
  if (key === 'accessControl') return 'AccessControl';

  if (key === 'aiRelatedFeature') return 'AiRelatedFeature';

  if (key === 'api') return 'Api';

  if (key === 'architecture') return 'Architecture';

  if (key === 'businessLogic') return 'BusinessLogic';

  if (key === 'cryptography') return 'Cryptography';

  if (key === 'dataChanges') return 'DataChanges';

  // if (key === 'designDecisions') return 'DesignDecisions';

  if (key === 'infrastructure') return 'Infrastructure';

  if (key === 'userFlows') return 'UserFlows';

  if (key === 'pii') return 'Pii';

  if (key === 'sensitiveInformation') return 'SensitiveInformation';

  if (key === 'thirdPartyComponent') return 'ThirdPartyComponent';

  return 'UNKNOWN';
};

export const ZodKnowledgeItemLabelDataDto = z.object({
  changeDetails: z.string(),
  detectedKeywords: z.string().array().optional().default([]),
});

export type KnowledgeItemLabelDataDto = z.infer<typeof ZodKnowledgeItemLabelDataDto>;

export const castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData = (
  dto: KnowledgeItemLabelDataDto
): KnowledgeItemLabelData => {
  return {
    changeDetails: dto.changeDetails,
    detectedKeywords: dto.detectedKeywords,
  };
};

export const ZodKnowledgeItemAccessControlLabelDto = z
  .object({
    authorization: z.boolean(),
    authentication: z.boolean(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemAccessControlLabelDto = z.infer<
  typeof ZodKnowledgeItemAccessControlLabelDto
>;

export const castKnowledgeItemAccessControlLabelDtoToKnowledgeItemAccessControlLabel = (
  dto: KnowledgeItemAccessControlLabelDto
): KnowledgeItemAccessControlLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    authorization: dto.authorization,
    authentication: dto.authentication,
  };
};

export const ZodKnowledgeItemAiRelatedFeatureLabelDto = z
  .object({
    modelProvider: z.string(),
    modelInformation: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemAiRelatedFeatureLabelDto = z.infer<
  typeof ZodKnowledgeItemAiRelatedFeatureLabelDto
>;

export const castKnowledgeItemAiRelatedFeatureLabelDtoToKnowledgeItemAiRelatedFeatureLabel = (
  dto: KnowledgeItemAiRelatedFeatureLabelDto
): KnowledgeItemAiRelatedFeatureLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    modelProvider: dto.modelProvider,
    modelInformation: dto.modelInformation,
  };
};

export const ZodKnowledgeItemApiLabelDto = z
  .object({
    external: z.string(),
    specificApis: z
      .array(
        z.object({
          apiName: z.string(),
          changeType: z.string(),
          type: z.string(),
        })
      )
      .default([]),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemApiLabelDto = z.infer<typeof ZodKnowledgeItemApiLabelDto>;

export const castKnowledgeItemApiLabelDtoToKnowledgeItemApiLabel = (
  dto: KnowledgeItemApiLabelDto
): KnowledgeItemApiLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    external: dto.external,
    specificApis: dto.specificApis.map((api) => ({
      apiName: api.apiName,
      changeType: api.changeType,
      type: api.type,
    })),
  };
};

export const ZodKnowledgeItemCryptographyLabelDto = z
  .object({
    cryptoType: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemCryptographyLabelDto = z.infer<
  typeof ZodKnowledgeItemCryptographyLabelDto
>;

export const castKnowledgeItemCryptographyLabelDtoToKnowledgeItemCryptographyLabel = (
  dto: KnowledgeItemCryptographyLabelDto
): KnowledgeItemCryptographyLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    cryptoType: dto.cryptoType,
  };
};

export const ZodKnowledgeItemDataChangesLabelDto = z
  .object({
    typeOfData: z.string(),
    typeOfChange: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemDataChangesLabelDto = z.infer<typeof ZodKnowledgeItemDataChangesLabelDto>;

export const castKnowledgeItemDataChangesLabelDtoToKnowledgeItemDataChangesLabel = (
  dto: KnowledgeItemDataChangesLabelDto
): KnowledgeItemDataChangesLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    typeOfData: dto.typeOfData,
    typeOfChange: dto.typeOfChange,
  };
};

export const ZodKnowledgeItemInfrastructureLabelDto = z
  .object({
    changeType: z.string(),
    isMajorChange: z.boolean(),
    technology: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemInfrastructureLabelDto = z.infer<
  typeof ZodKnowledgeItemInfrastructureLabelDto
>;

export const castKnowledgeItemInfrastructureLabelDtoToKnowledgeItemInfrastructureLabel = (
  dto: KnowledgeItemInfrastructureLabelDto
): KnowledgeItemInfrastructureLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    changeType: dto.changeType,
    isMajorChange: dto.isMajorChange,
    technology: dto.technology,
  };
};

export const ZodKnowledgeItemThirdPartyComponentLabelDto = z
  .object({
    type: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemThirdPartyComponentLabelDto = z.infer<
  typeof ZodKnowledgeItemThirdPartyComponentLabelDto
>;

export const castKnowledgeItemThirdPartyComponentLabelDtoToKnowledgeItemThirdPartyComponentLabel = (
  dto: KnowledgeItemThirdPartyComponentLabelDto
): KnowledgeItemThirdPartyComponentLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    type: dto.type,
  };
};

export const ZodKnowledgeItemUserFlowsLabelDto = z
  .object({
    flowChangeType: z.string(),
  })
  .merge(ZodKnowledgeItemLabelDataDto);

export type KnowledgeItemUserFlowsLabelDto = z.infer<typeof ZodKnowledgeItemUserFlowsLabelDto>;

export const castKnowledgeItemUserFlowsLabelDtoToKnowledgeItemUserFlowsLabel = (
  dto: KnowledgeItemUserFlowsLabelDto
): KnowledgeItemUserFlowsLabel => {
  const labelData = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(dto);

  return {
    ...labelData,
    flowChangeType: dto.flowChangeType,
  };
};

export const ZodKnowledgeItemLabelsDto = z.object({
  ['accessControl']: ZodKnowledgeItemAccessControlLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('accessControl parsing errored! see:', e);

      return null;
    }),
  ['aiRelatedFeature']: ZodKnowledgeItemAiRelatedFeatureLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('aiRelatedFeature parsing errored! see:', e);

      return null;
    }),
  ['api']: ZodKnowledgeItemApiLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('api parsing errored! see:', e);

      return null;
    }),
  ['architecture']: ZodKnowledgeItemLabelDataDto.nullable()
    .optional()
    .catch((e) => {
      console.error('architecture parsing errored! see:', e);

      return null;
    }),
  ['businessLogic']: ZodKnowledgeItemLabelDataDto.nullable()
    .optional()
    .catch((e) => {
      console.error('businessLogic parsing errored! see:', e);

      return null;
    }),
  ['cryptography']: ZodKnowledgeItemCryptographyLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('cryptography parsing errored! see:', e);

      return null;
    }),
  ['dataChanges']: ZodKnowledgeItemDataChangesLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('dataChanges parsing errored! see:', e);

      return null;
    }),
  // ['designDecisions']: ZodKnowledgeItemLabelDataDto.nullable()
  //   .optional()
  //   .catch((e) => {
  //     console.error('designDecisions parsing errored! see:', e);
  //
  //     return null;
  //   }),
  ['infrastructure']: ZodKnowledgeItemInfrastructureLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('infrastructure parsing errored! see:', e);

      return null;
    }),
  ['pii']: ZodKnowledgeItemLabelDataDto.nullable()
    .optional()
    .catch((e) => {
      console.error('pii parsing errored! see:', e);

      return null;
    }),
  ['sensitiveInformation']: ZodKnowledgeItemLabelDataDto.nullable()
    .optional()
    .catch((e) => {
      console.error('sensitiveInformation parsing errored! see:', e);

      return null;
    }),
  ['thirdPartyComponent']: ZodKnowledgeItemThirdPartyComponentLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('thirdPartyComponent parsing errored! see:', e);

      return null;
    }),
  ['userFlows']: ZodKnowledgeItemUserFlowsLabelDto.nullable()
    .optional()
    .catch((e) => {
      console.error('userFlows parsing errored! see:', e);

      return null;
    }),
});

export type KnowledgeItemLabelsDto = z.infer<typeof ZodKnowledgeItemLabelsDto>;

export const castKnowledgeItemLabelsDtoToKnowledgeItemLabels = (
  dto: KnowledgeItemLabelsDto
): KnowledgeItemLabels => {
  return Object.entries(dto).reduce((acc, [_key, value]) => {
    if (!value) return acc;

    const key = _key as KeyOf<KnowledgeItemLabels>;

    switch (key) {
      case 'accessControl':
        acc.accessControl = castKnowledgeItemAccessControlLabelDtoToKnowledgeItemAccessControlLabel(
          value as KnowledgeItemAccessControlLabelDto
        );

        break;

      case 'aiRelatedFeature':
        acc.aiRelatedFeature =
          castKnowledgeItemAiRelatedFeatureLabelDtoToKnowledgeItemAiRelatedFeatureLabel(
            value as KnowledgeItemAiRelatedFeatureLabelDto
          );

        break;

      case 'api':
        acc.api = castKnowledgeItemApiLabelDtoToKnowledgeItemApiLabel(
          value as KnowledgeItemApiLabelDto
        );

        break;

      case 'architecture':
        acc.architecture = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(
          value as KnowledgeItemLabelDataDto
        );

        break;

      case 'businessLogic':
        acc.businessLogic = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(
          value as KnowledgeItemLabelDataDto
        );

        break;

      case 'cryptography':
        acc.cryptography = castKnowledgeItemCryptographyLabelDtoToKnowledgeItemCryptographyLabel(
          value as KnowledgeItemCryptographyLabelDto
        );

        break;

      case 'dataChanges':
        acc.dataChanges = castKnowledgeItemDataChangesLabelDtoToKnowledgeItemDataChangesLabel(
          value as KnowledgeItemDataChangesLabelDto
        );

        break;

      case 'infrastructure':
        acc.infrastructure =
          castKnowledgeItemInfrastructureLabelDtoToKnowledgeItemInfrastructureLabel(
            value as KnowledgeItemInfrastructureLabelDto
          );

        break;

      case 'pii':
        acc.pii = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(
          value as KnowledgeItemLabelDataDto
        );

        break;

      case 'sensitiveInformation':
        acc.sensitiveInformation = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(
          value as KnowledgeItemLabelDataDto
        );

        break;

      case 'thirdPartyComponent':
        acc.thirdPartyComponent =
          castKnowledgeItemThirdPartyComponentLabelDtoToKnowledgeItemThirdPartyComponentLabel(
            value as KnowledgeItemThirdPartyComponentLabelDto
          );

        break;

      case 'userFlows':
        acc.userFlows = castKnowledgeItemUserFlowsLabelDtoToKnowledgeItemUserFlowsLabel(
          value as KnowledgeItemUserFlowsLabelDto
        );

        break;

      default:
        // acc[key] = castKnowledgeItemLabelDataDtoToKnowledgeItemLabelData(
        //   value as KnowledgeItemLabelDataDto
        // );

        break;
    }

    return acc;
  }, {} as KnowledgeItemLabels);
};
