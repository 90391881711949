// App - Types
import type { KnowledgeSource } from '@/types/integration';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.integration['knowledge-source'];

export const translateKnowledgeSource = (source: KnowledgeSource): string => {
  if (source === 'confluence') return locale['Confluence'];

  if (source === 'google_drive') return locale['Google Drive'];

  if (source === 'jira') return locale['Jira'];

  if (source === 'trello') return locale['Trello'];

  if (source === 'linear') return locale['Linear'];

  if (source === 'github') return locale['Github'];

  if (source === 'github_issue') return locale['Github (Issues)'];

  if (source === 'github_pull_request') return locale['Github (PRs)'];

  if (source === 'azure_devops_wiki_page') return locale['Azure DevOps (Wikis)'];

  if (source === 'azure_devops_work_item') return locale['Azure DevOps (Work items)'];

  if (source === 'azure_devops_repository') return locale['Azure DevOps (Repositories)'];

  if (source === 'share_point') return locale['SharePoint'];

  if (source === 'notion') return locale['Notion'];

  // if (source === 'gitlab') return locale['GitLab'];

  if (source === 'service_now') return locale['ServiceNow'];

  return locale['Unknown'];
};
