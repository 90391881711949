export const EntityContributingUserRoles = [
  'assignee',
  'author',
  'commenter',
  'closer',
  'editor',
  'organizer',
  'reporter',
  'reviewer',
  'viewer',
  'UNKNOWN',
] as const;

export type EntityContributingUserRole = (typeof EntityContributingUserRoles)[number];

export type EntityContributingUser = {
  email?: string;
  name?: string;
  roles: EntityContributingUserRole[];
  sourceId: string;
};

export const compareEntityContributingUsers = (
  a: EntityContributingUser,
  b: EntityContributingUser
): boolean => {
  return (
    a.sourceId === b.sourceId &&
    a.email === b.email &&
    a.name === b.name &&
    a.roles.length === b.roles.length
  );
};

export const getAuthorOrDefault = (
  contributingUsers: EntityContributingUser[]
): EntityContributingUser => {
  return (
    contributingUsers.find((user) => user.roles.includes('author')) || {
      email: '',
      name: '',
      roles: ['UNKNOWN'],
      sourceId: '',
    }
  );
};
