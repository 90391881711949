// App - Types
import type { KnowledgeSource } from '@/types/integration';

export const translateKnowledgeSourceToColor = (source: KnowledgeSource): string => {
  if (source === 'confluence') return '#0055CC';

  if (source === 'google_drive') return '#34A853';

  if (source === 'jira') return '#388BFF';

  if (source === 'trello') return '#298FCA';

  if (source === 'linear') return '#5E6AD2';

  if (source === 'github') return '#24292E';

  if (source === 'github_issue') return '#24292E';

  if (source === 'github_pull_request') return '#24292E';

  if (source === 'share_point') return '#03787C';

  if (source === 'notion') return '#000000';

  // if (source === 'gitlab') return '#FCA121';

  if (source === 'azure_devops') return '#1F88DC';

  if (source === 'azure_devops_wiki_page') return '#1F88DC';

  if (source === 'azure_devops_work_item') return '#00947D';

  if (source === 'azure_devops_repository') return '#E15815';

  if (source === 'service_now') return '#62d84e';

  return 'transparent';
};
