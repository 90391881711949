// React & Next
import { createElement } from 'react';

// App - Types
import type { KnowledgeSource } from '@/types/integration';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import {
  AzureDevOpsIcon,
  AzureDevOpsWikiIcon,
  AzureDevOpsWorkItemIcon,
  AzureDevOpsRepositoryIcon,
  ColorableConfluenceIcon,
  ColorableGoogleDriveIcon,
  ColorableJiraIcon,
  ConfluenceIcon,
  GithubIcon,
  // GitlabIcon,
  GoogleDriveIcon,
  ZendeskIcon,
  JiraIcon,
  LinearIcon,
  NotionIcon,
  TrelloIcon,
  SharePointIcon,
  ServiceNowIcon,
  UnknownIcon,
} from '@/components/atoms/icon';
import { translateKnowledgeSource } from './knowledge-source';

type KnowledgeSourceIconProps = Partial<IconProps> & {
  source: KnowledgeSource;
};

export const KnowledgeSourceIcon = ({ source, ...iconProps }: KnowledgeSourceIconProps) => {
  return createElement(translateKnowledgeSourceToIcon(source), {
    'aria-label': translateKnowledgeSource(source),
    ...iconProps,
  });
};

export const translateKnowledgeSourceToIcon = (source: KnowledgeSource) => {
  if (!source) return UnknownIcon;

  if (source === 'confluence') return ConfluenceIcon;

  if (source === 'google_drive') return GoogleDriveIcon;

  if (source === 'jira') return JiraIcon;

  if (source === 'trello') return TrelloIcon;

  if (source === 'linear') return LinearIcon;

  if (source === 'github') return GithubIcon;

  if (source === 'github_pull_request') return GithubIcon;

  if (source === 'github_issue') return GithubIcon;

  if (source === 'azure_devops') return AzureDevOpsIcon;

  if (source === 'azure_devops_wiki_page') return AzureDevOpsWikiIcon;

  if (source === 'azure_devops_work_item') return AzureDevOpsWorkItemIcon;

  if (source === 'azure_devops_repository') return AzureDevOpsRepositoryIcon;

  if (source === 'share_point') return SharePointIcon;

  if (source === 'notion') return NotionIcon;

  if (source === 'zendesk') return ZendeskIcon;

  // if (source === 'gitlab') return GitlabIcon;

  if (source === 'service_now') return ServiceNowIcon;

  return UnknownIcon;
};

export const translateKnowledgeSourceToColorableIcon = (source: KnowledgeSource) => {
  if (!source) return UnknownIcon;

  if (source === 'confluence') return ColorableConfluenceIcon;

  if (source === 'google_drive') return ColorableGoogleDriveIcon;

  if (source === 'jira') return ColorableJiraIcon;

  return UnknownIcon;
};
